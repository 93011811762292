import type { ComponentProps } from 'react'

import { DashboardSidebar } from './DashboardSidebar/DashboardSidebar'
import { PageContent } from './PageContent'

export const DashboardLayout = ({ children }: ComponentProps<'div'>) => {
  return (
    <>
      <DashboardSidebar />
      <PageContent>{children}</PageContent>
    </>
  )
}
