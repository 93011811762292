import { cn, pluralize } from '@hapstack/common'
import { FREE_TRIAL_DAYS } from '@hapstack/constants/billing'
import { TextLink } from '@hapstack/remix'
import { useEffect, useState } from 'react'

import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'

import { useAuthenticatedTeam } from '../../../routes/_dashboard/useAuthenticatedTeam'
import { AnnouncementBar } from './AnnouncementBar'
import { SIDEBAR_WIDTH } from './DashboardSidebar/DashboardSidebar'

const PADDING_X = 32

export const PageContent = ({ children }: { children: React.ReactNode }) => {
  const team = useAuthenticatedTeam()

  const { APP_ENV } = useClientEnvironmentVariables()

  const trialIsActive =
    team.billing?.status === 'trialing' && !!team.billing.trialDaysRemaining

  const isOverUserLimit =
    !trialIsActive &&
    team.activeUserCount > (team.billing?.maxUsers || Number.MAX_SAFE_INTEGER)

  const showAnnouncementBar = isOverUserLimit || trialIsActive

  return (
    <main
      className="relative mb-12 w-full"
      style={{
        marginLeft: SIDEBAR_WIDTH,
      }}
    >
      {APP_ENV === 'demo' ? <DemoModeAnnouncementBar /> : null}
      <BillingAnnouncementBar
        message={trialIsActive ? 'trial' : 'upgrade'}
        visible={showAnnouncementBar}
      />

      <div
        className={cn(
          'mr-8 h-full min-h-screen overflow-y-scroll pt-8',
          APP_ENV === 'demo' && 'pt-16'
        )}
        style={{
          maxWidth: `calc(100vw - ${SIDEBAR_WIDTH}px)`,
          marginLeft: PADDING_X,
          paddingRight: PADDING_X,
        }}
      >
        {children}
      </div>
    </main>
  )
}

const DemoModeAnnouncementBar = () => {
  const { CONSTANTS } = useClientEnvironmentVariables()

  const [visible, setVisible] = useState(true)

  // Expose a global function to toggle the banner
  // so we can use demo mode during customer-facing demos.
  useEffect(() => {
    if (typeof window === 'undefined') return

    // @ts-expect-error
    window.toggleBanner = () => {
      setVisible((prev) => !prev)
    }

    return () => {
      // @ts-expect-error
      delete window.toggleBanner
    }
  }, [])

  if (!visible) return null

  return (
    <AnnouncementBar className="h-fit">
      <div className="py-1.5">
        <p>Demo mode is active. No changes will be saved.</p>
        <p>
          Build your own SaaS dashboard in minutes - no credit card required.{' '}
          <TextLink
            variant="underline"
            className="underline-offset-4"
            to={CONSTANTS.WEB_APP_URL}
          >
            Start {FREE_TRIAL_DAYS} day free trial →
          </TextLink>{' '}
        </p>
      </div>
    </AnnouncementBar>
  )
}

const BillingAnnouncementBar = ({
  message,
  visible,
}: {
  message: 'trial' | 'upgrade'
  visible: boolean
}) => {
  const team = useAuthenticatedTeam()

  if (!visible) return null

  return (
    <AnnouncementBar className={cn(message === 'upgrade' && 'bg-error-600')}>
      {message === 'upgrade' ? (
        <p>
          You're over your plan limit of {team.billing?.maxUsers} users. Please{' '}
          <TextLink
            variant="underline"
            to="/settings/billing"
          >
            upgrade your plan
          </TextLink>
          .
        </p>
      ) : (
        <p>
          You have {team.billing?.trialDaysRemaining}{' '}
          {pluralize(team.billing?.trialDaysRemaining || 0, 'day')} left in your{' '}
          trial.{' '}
          <TextLink
            variant="underline"
            to="/settings/billing"
          >
            Manage subscription
          </TextLink>
          .
        </p>
      )}
    </AnnouncementBar>
  )
}
