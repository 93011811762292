import { RoleGuard } from '~/components/shared/RoleGuard'
import { useAuthenticatedUser } from '~/routes/_dashboard/useAuthenticatedUser'

import { DashboardSidebarLink } from './DashboardSidebarLink'

export const DashboardSidebarNav = () => {
  const { id } = useAuthenticatedUser()

  return (
    <div className="h-full">
      <div className="flex h-full flex-col justify-between">
        <ul className="space-y-2">
          <RoleGuard>
            <li>
              <DashboardSidebarLink
                to="/"
                icon="gauge-circle"
                end
              >
                Dashboard
              </DashboardSidebarLink>
            </li>

            <li>
              <DashboardSidebarLink
                to="/stack"
                icon="layers-3"
              >
                Stack
              </DashboardSidebarLink>
            </li>

            <li>
              <DashboardSidebarLink
                to="/renewals"
                icon="calendar"
              >
                Renewals
              </DashboardSidebarLink>
            </li>

            <li>
              <DashboardSidebarLink
                to="/insights"
                icon="lightbulb"
              >
                Insights
              </DashboardSidebarLink>
            </li>

            <li>
              <DashboardSidebarLink
                to="/team"
                icon="users-2"
              >
                Team
              </DashboardSidebarLink>
            </li>

            <li>
              <DashboardSidebarLink
                to="/settings"
                icon="settings"
              >
                Settings
              </DashboardSidebarLink>
            </li>
          </RoleGuard>
          <RoleGuard role="user">
            <li>
              <DashboardSidebarLink
                to={`/team/${id}`}
                icon="mouse-pointer-click"
              >
                My Activity
              </DashboardSidebarLink>
            </li>
          </RoleGuard>
        </ul>
      </div>
    </div>
  )
}
