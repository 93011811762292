import { useForm } from '@hapstack/remix'
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@hapstack/ui'
import { useState } from 'react'

import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'

import { INTENTS } from '../_dashboard._index/types'

const SHOW_FOR_VERSIONS = ['1.2.0']

export const ReleaseDialog = ({ defaultOpen }: { defaultOpen: boolean }) => {
  const { APP_VERSION } = useClientEnvironmentVariables()
  const [open, setOpen] = useState(
    SHOW_FOR_VERSIONS.includes(APP_VERSION) && defaultOpen
  )

  const form = useForm({
    intent: INTENTS.Enum.ackReleaseDialog,
    // See /_dashboard._index/index.tsx for form action
    submitConfig: { action: '/?index' },
    useFetcher: true,
    onSuccess: () => setOpen(false),
  })

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      modal
    >
      <DialogContent
        onInteractOutside={(e) => e.preventDefault()}
        hideCloseButton
        className="max-w-2xl"
      >
        <DialogHeader>
          <DialogTitle>✨ New: Simpler Email Reminders</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-6">
          <p className="text-base">
            Email reminders can now be configured in one place, and they'll
            apply to all of your subscriptions at once. Check out the video
            below to learn more.
          </p>
          <div className="relative aspect-video overflow-hidden rounded-lg">
            <iframe
              src="https://www.loom.com/embed/989dd8dd1de84b1abacbdbcdcf6e5e1a?sid=73373621-cce2-4f65-87bc-f51a98eab32b"
              className="absolute inset-0 h-full w-full"
            ></iframe>
          </div>
        </div>

        <DialogFooter>
          <Button
            onClick={form.handleSubmit}
            loading={form.formState.isSubmitting}
            variant="dark"
          >
            Got it!
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
