import * as Sentry from '@sentry/remix'
import { useEffect } from 'react'

import { useAuthenticatedUser } from '~/routes/_dashboard/useAuthenticatedUser'

export const useSentry = () => {
  const user = useAuthenticatedUser()

  useEffect(() => {
    user ? Sentry.setUser({ email: user.email }) : Sentry.setUser(null)
  }, [user])

  return null
}
