import { cn } from '@hapstack/common'
import { Logo, Spacer } from '@hapstack/ui'
import { Link } from '@remix-run/react'

import { DashboardSidebarNav } from './DashboardSidebarNav'
import { DashboardSidebarUser } from './DashboardSidebarUser'

export const SIDEBAR_WIDTH = 224

export const DashboardSidebar = () => {
  return (
    <nav
      aria-labelledby="sidebar"
      className={cn(
        'fixed z-20 flex h-full min-h-screen flex-col justify-start bg-primary px-3 py-2 text-white'
      )}
      style={{ width: SIDEBAR_WIDTH }}
    >
      <Link
        to="/"
        className={cn('flex pl-3 pt-4')}
      >
        <Logo
          variant="white"
          className="w-28"
        />
      </Link>

      <Spacer
        size="md"
        className="block"
      />

      <div className="flex h-full flex-col justify-between">
        <DashboardSidebarNav />
        <DashboardSidebarUser />
      </div>
    </nav>
  )
}
