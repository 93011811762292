import Intercom from '@intercom/messenger-js-sdk'
import { useEffect } from 'react'

import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'
import { useDashboardLoaderData } from '~/routes/_dashboard/useDashboardLoaderData'

export const useLiveChat = () => {
  const { APP_ENV, INTERCOM_APP_ID } = useClientEnvironmentVariables()
  const { intercomHash, authenticatedUser, authenticatedTeam } =
    useDashboardLoaderData()

  if (APP_ENV === 'demo') return null

  useEffect(() => {
    if (typeof window === 'undefined') return

    const createdAt = new Date(authenticatedUser.createdAt)
    const unixTimestamp = Math.floor(createdAt.getTime() / 1000)

    Intercom({
      api_base: 'https://api-iam.intercom.io',
      app_id: INTERCOM_APP_ID,
      name: authenticatedUser.fullName || 'Anonymous',
      user_id: String(authenticatedUser.id),
      email: authenticatedUser.email,
      created_at: unixTimestamp,
      user_hash: intercomHash,
      avatar: {
        type: 'avatar',
        image_url: authenticatedUser.avatarUrl,
      },
      company: {
        company_id: authenticatedTeam.id,
        name: authenticatedTeam.name,
        created_at: authenticatedTeam.createdAt,
        plan: authenticatedTeam.billing?.planName,
      },
      role: authenticatedUser.role,
    })
  }, [authenticatedUser, authenticatedTeam, intercomHash, INTERCOM_APP_ID])
}
