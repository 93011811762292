import { cn } from '@hapstack/common'
import type { ComponentProps } from 'react'

export const AnnouncementBar = ({
  className,
  children,
  ...props
}: ComponentProps<'div'>) => {
  return (
    <div className="sticky top-0 z-10 h-8 w-full">
      <div
        className={cn(
          'flex h-full w-full items-center justify-center bg-pink-100 text-center text-sm font-medium text-pink-900',
          className
        )}
        {...props}
      >
        {children}
      </div>
    </div>
  )
}
