import type { IconName } from '@hapstack/ui'
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  StyledIcon,
} from '@hapstack/ui'
import type { ComponentProps } from 'react'

import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'

export const DemoDialog = ({ ...props }: ComponentProps<typeof Dialog>) => {
  const { CONSTANTS } = useClientEnvironmentVariables()

  return (
    <Dialog
      {...props}
      modal
    >
      <DialogContent
        onInteractOutside={(e) => e.preventDefault()}
        className="max-w-xl"
      >
        <DialogHeader>
          <DialogTitle>Welcome to {CONSTANTS.APP_NAME}.</DialogTitle>
        </DialogHeader>
        <div className="my-4 space-y-8">
          <Benefit
            icon="boxes"
            title="Organize your apps."
            description="Build a single source of truth to keep your team in sync."
          />
          <Benefit
            icon="mouse-pointer-click"
            title="Track activity."
            description="Use our Google Workspace connection or browser extension for instant visibility to your team’s usage – including Shadow IT."
          />
          <Benefit
            icon="dollar-sign"
            title="Save money."
            description="Never miss another renewal, identify wasted spend on underused apps, and avoid redudancies across your team."
          />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              icon="arrow-right"
              className="flex-row-reverse"
            >
              Check it out
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

const Benefit = ({
  icon,
  title,
  description,
}: {
  icon: IconName
  title: string
  description: string
}) => {
  return (
    <div className="flex items-start gap-4">
      <StyledIcon
        color="purple"
        size="lg"
        name={icon}
      />
      <div>
        <div className="mb-1 text-base font-medium leading-none">{title}</div>
        <p className="text-pretty text-muted-foreground">{description}</p>
      </div>
    </div>
  )
}
