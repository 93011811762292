import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@hapstack/ui'
import type { ComponentProps } from 'react'

import { CreatePortalSessionButton } from '../resources.billing/CreatePortalSessionButton'
import { ProductList } from '../resources.billing/ProductList'
import { useAuthenticatedTeam } from './useAuthenticatedTeam'

export const UpgradeDialog = (props: ComponentProps<typeof Dialog>) => {
  const { billing } = useAuthenticatedTeam()

  return (
    <Dialog
      {...props}
      modal
    >
      <DialogContent
        hideCloseButton
        onInteractOutside={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>
            {billing?.hasExpiredTrial
              ? 'Finish setting up your plan'
              : 'Select a plan to continue'}
          </DialogTitle>
        </DialogHeader>

        {billing?.hasExpiredTrial ? (
          <div className="space-y-4">
            <p>
              Your free trial period has expired. Enter a payment method and
              confirm your plan to regain access.
            </p>
            <CreatePortalSessionButton
              customerId={billing.customerId!}
              subscriptionId={billing.subscriptionId}
              icon="credit-card"
            >
              Set up plan
            </CreatePortalSessionButton>
          </div>
        ) : (
          <ProductList />
        )}
      </DialogContent>
    </Dialog>
  )
}
