import type { LoaderFunctionArgs } from '@remix-run/node'
import { redirect } from '@remix-run/node'
import { Outlet, useLoaderData } from '@remix-run/react'
import { collect } from 'composable-functions'

import { AppLayout } from '~/components/layouts/AppLayout'
import { DashboardLayout } from '~/components/layouts/dashboard/DashboardLayout'
import { RouteErrorCard } from '~/components/shared/RouteErrorCard'
import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'
import { useLiveChat } from '~/hooks/useLiveChat'
import { useProductAnalytics } from '~/hooks/useProductAnalytics'
import { useSentry } from '~/hooks/useSentry'
import { getFeatureFlags } from '~/services/posthog/posthog.server'
import { query } from '~/utils/query.server'

import { DemoDialog } from './DemoDialog'
import { getAuthenticatedTeam } from './queries/getAuthenticatedTeam.server'
import { getAuthenticatedUser } from './queries/getAuthenticatedUser.server'
import { getIntercomHash } from './queries/getIntercomHash.server'
import { getHasUserAckedReleaseMessage } from './queries/getShowReleaseDialog.server'
import { ReleaseDialog } from './ReleaseDialog'
import { UpgradeDialog } from './UpgradeDialog'

const getData = collect({
  authenticatedUser: getAuthenticatedUser,
  authenticatedTeam: getAuthenticatedTeam,
  featureFlags: getFeatureFlags,
  intercomHash: getIntercomHash,
  hasAckedReleaseMessage: getHasUserAckedReleaseMessage,
})

export const loader = async ({ request }: LoaderFunctionArgs) => {
  return query({
    request,
    query: getData,
    allowedRoles: ['admin', 'user'],
    environment: { request },
    beforeSuccess: async (result) => {
      const { authenticatedUser: user } = result.data
      if (!user.fullName) throw redirect('/onboarding/profile')
      if (user.role !== 'admin' && !user.isTracked)
        throw redirect('/onboarding/extension')
    },
  })
}

export default function DashboardLayoutRoute() {
  const { APP_ENV } = useClientEnvironmentVariables()

  const { hasAckedReleaseMessage, authenticatedTeam } =
    useLoaderData<typeof loader>()

  useProductAnalytics()
  useLiveChat()
  useSentry()

  const hasActiveSubscription =
    authenticatedTeam?.billing?.hasActiveSubscription ?? false

  const displayReleaseDialog = !hasAckedReleaseMessage && hasActiveSubscription

  const displayUpgradeDialog = !hasActiveSubscription && APP_ENV !== 'demo'

  return (
    <AppLayout>
      <DashboardLayout>
        <Outlet />
        <DemoDialog defaultOpen={APP_ENV === 'demo'} />
        <ReleaseDialog defaultOpen={displayReleaseDialog} />
        <UpgradeDialog defaultOpen={displayUpgradeDialog} />
      </DashboardLayout>
    </AppLayout>
  )
}

export function ErrorBoundary() {
  useSentry()

  return (
    <AppLayout>
      <DashboardLayout>
        <div className="w-full max-w-2xl p-4">
          <RouteErrorCard />
        </div>
      </DashboardLayout>
    </AppLayout>
  )
}
