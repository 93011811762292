import { cn } from '@hapstack/common'
import type { IconName } from '@hapstack/ui'
import { Icon } from '@hapstack/ui'
import type { NavLinkProps } from '@remix-run/react'
import { NavLink } from '@remix-run/react'

type SidebarLinkProps = {
  icon: IconName
  children: React.ReactNode
} & NavLinkProps

export const DashboardSidebarLink = ({
  icon,
  children,
  className,
  ...props
}: SidebarLinkProps) => {
  return (
    <NavLink
      prefetch="intent"
      className={({ isActive }) =>
        cn(
          'group block w-auto rounded-md transition-all transition-opacity duration-200 duration-300 hover:bg-secondary/10',
          isActive ? 'bg-secondary/20 [&_svg]:text-accent' : 'bg-transparent',
          className
        )
      }
      {...props}
    >
      <div
        className={cn(
          'flex items-center justify-start gap-2 px-3 py-2 transition-opacity duration-200'
        )}
      >
        <div className="flex size-5 items-center justify-center">
          <Icon
            name={icon}
            size="sm"
          />
        </div>

        <span>{children}</span>
      </div>
    </NavLink>
  )
}
