import { cn } from '@hapstack/common'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Icon,
  Separator,
} from '@hapstack/ui'
import { useNavigate, useSubmit } from '@remix-run/react'

import { UserAvatar } from '~/components/ui/User'
import { useAuthenticatedUser } from '~/routes/_dashboard/useAuthenticatedUser'

export const DashboardSidebarUser = () => {
  const user = useAuthenticatedUser()
  const submit = useSubmit()
  const navigate = useNavigate()

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault()
    submit(null, { method: 'POST', action: '/logout' })
  }

  return (
    <div className="flex flex-col">
      <Separator className="my-4 opacity-30" />

      <div
        className={cn(
          'hidden items-center justify-between gap-2 rounded-lg bg-secondary/10 p-4 md:flex'
        )}
      >
        <div className="flex items-center gap-2">
          <div className="flex shrink-0 items-center justify-center">
            <UserAvatar
              size="sm"
              src={user.avatarUrl}
              fallback={user.fullName}
            />
          </div>

          <div className="max-w-28 truncate">
            {user.displayName || user.email}
          </div>
        </div>

        <DropdownMenu>
          <DropdownMenuTrigger className="shrink-0">
            <Icon name="settings" />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align="start"
            side="top"
            sideOffset={10}
            className="min-w-48"
          >
            <DropdownMenuItem
              icon="user-circle-2"
              onSelect={() => navigate('/account')}
            >
              My settings
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={handleLogout}
              icon="log-out"
            >
              Log out
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
