import { useBeforeUnload, useLocation } from '@remix-run/react'
import posthog from 'posthog-js'
import { useEffect } from 'react'

import { useAuthenticatedUser } from '~/routes/_dashboard/useAuthenticatedUser'

import { useClientEnvironmentVariables } from './useClientEnvironmentVariables'

export const useProductAnalytics = () => {
  const user = useAuthenticatedUser()
  const { APP_ENV, POSTHOG_API_KEY } = useClientEnvironmentVariables()
  const { pathname } = useLocation()

  useEffect(() => {
    if (typeof document === 'undefined') return
    if (APP_ENV === 'demo') return
    posthog.init(POSTHOG_API_KEY, {
      capture_pageview: false,
      autocapture: false,
      bootstrap: {
        distinctID: String(user.id),
      },
    })
  }, [user.id, APP_ENV, POSTHOG_API_KEY])

  useEffect(() => {
    if (typeof document === 'undefined') return
    if (APP_ENV === 'demo') return
    posthog.capture('$pageview')

    return () => {
      posthog.capture('$pageleave')
    }
  }, [pathname, APP_ENV])

  useBeforeUnload(() => {
    if (APP_ENV === 'demo') return
    posthog.capture('$pageleave')
  })
}
